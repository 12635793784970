import * as React from "react";
import { PostMessage } from "../utils/webFramed";
import { Button, Spinner } from "react-bootstrap";
import { fetchReservationDetails } from "../services/crs";
import moment from "moment-mini";
import { resParseDateFormat } from "../services/dates";
import { useHotels } from "../hooks/useHotels";
import { filter } from "lodash";
import EditReservationButton from "../components/common/EditReservationButton/EditReservationButton";
import WebFramed from "../components/common/WebFramed/WebFramed";
import { Edit_Reservation_Failure } from "../@types/Constants";
import { Container } from "../pageHelpers/ModifyReservationNative/ModifyReservationNativeHelpers";

let tripDetails = { confirmationNumber: "", lastName: "", accessToken: "" };

const ModifyReservationNative = () => {
  const [resInfo, setResInfo] = React.useState<any>(null);
  const allHotels = useHotels();
  const [showSpinner, setShowSpinner] = React.useState(true);
  const [payload, setPayload] = React.useState(tripDetails);
  const [error, setError] = React.useState<any>(null);
  const handleEditResPayload = (data: any, type?: string) => {
    if (type === "error") {
      setError({
        type: Edit_Reservation_Failure,
        message: "",
      });
    }
    if (tripDetails?.confirmationNumber !== data.payload.confirmationNumber) {
      setPayload({ ...data.payload });
      tripDetails = data.payload;
    }
  };
  React.useEffect(() => {
    const handler = async () => {
      try {
        const response = await fetchReservationDetails({
          resId: payload.confirmationNumber, //12050SE136591
          lastName: payload.lastName, //Testing
        });
        if (response.error) {
          setError({
            type: Edit_Reservation_Failure,
            message: response.errorSummary,
          });
        }
        const reservation = response.value;
        const reservedHotel = filter(allHotels, (h, key) => {
          return reservation?.hotel.id == h.crs_code;
        });
        const _payload = {
          crs_reservation_id: payload.confirmationNumber, //reservation.ID,
          arrival: moment(reservation.Start, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          departure: moment(reservation.End, "YYYY-MM-DD").format(
            resParseDateFormat
          ),
          details: reservation, //{...reservation, HotelCode: '75577'}
          lastName: payload.lastName,
          hotel: reservedHotel[0],
        };
        setResInfo(_payload);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        PostMessage(
          `Edit reservation: fetch reservation details failed, error === ${JSON.stringify(
            e
          )}`
        );
      }
    };
    if (payload?.confirmationNumber) {
      handler();
    }
  }, [payload]);
  const handleEditResFailure = () => {
    PostMessage(error);
  };
  return (
    <div className="row text-center">
      <WebFramed
        isEditReservation={true}
        handleEditResPayload={handleEditResPayload}
      />
      {resInfo ? (
        <EditReservationButton
          reservation={resInfo?.details}
          hotel={resInfo?.hotel}
          confirmationID={resInfo?.crs_reservation_id}
          hideSpinner={setShowSpinner}
        />
      ) : null}
      {showSpinner &&
        (error ? (
          <Container>
            <p>
              {process.env.GATSBY_FRAMED_EDIT_RESERVATION_FAILURE ||
                `We are unable to process your request at this moment, please try again later.`}
            </p>
            <Button
              className="p-2 text-uppercase btn btn-primary"
              onClick={handleEditResFailure}
            >
              Ok
            </Button>
          </Container>
        ) : (
          <Container>
            <Spinner animation={"border"} />
          </Container>
        ))}
    </div>
  );
};

export default ModifyReservationNative;
